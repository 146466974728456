window.addEventListener( "pageshow", function ( event ) {
    var perfEntries = performance.getEntriesByType("navigation");
    if (perfEntries[0] && ( typeof perfEntries[0] === 'object') && perfEntries[0].type === "back_forward") {
        location.reload();
    }
});

var CheckoutJS = function() {
function CheckoutForm() {
    this.forms = {};
}

CheckoutForm.prototype.reloadSidebarSummary = function() {
    var box = $('#js-order-summary-box');
    var cetelemBox = $('#js-order-summary-cetelem-box');
    var url = box.data('url');
    $('#checkout-train').dimmer('show');

    (function() {
        var deferred = $.Deferred();

        var summary = $.ajax({
            type: 'POST',
            dataType: 'text',
            url: url,
            data: {
                detailed: 1
            }
        });

        if (cetelemBox.length) {
            var cetelem = $.ajax({
                type: 'GET',
                dataType: 'html',
                url: cetelemBox.data('url'),
                data: {
                    'width': 344,
                    'height': 321,
                    'variant': 'allo',
                    'disableBigBadge': true,
                    'disableNotice': true,
                    'disableMoreInfo': true,
                    'useTotal': true
                }
            });

            $.when(summary, cetelem).done(
                // Each argument is an array with the following structure: [ data, statusText, jqXHR ]
                function(summaryData, cetelemData) {
                    if (summaryData && summaryData[1] === 'success') {
                        box.html($(summaryData[0]).html());
                    }

                    if (cetelemData && cetelemData[1] === 'success' && cetelemBox.length) {
                        cetelemBox.empty();
                        cetelemBox.append(cetelemData[0]);
                    }
                    deferred.resolve();
                }
            );
        } else {
            $.when(summary).done(
                function(summaryData, textStatus) {
                    if (textStatus === 'success' && summaryData) {
                        box.html($(summaryData).html());
                    }
                    deferred.resolve();
                }
            );
        }

        return deferred.promise();
    })().then(
        function() {
            $('#checkout-train').dimmer('hide');
        }
    );

};

CheckoutForm.prototype.addForm = function(name, object) {
    this.forms[name] = object;
};

CheckoutForm.prototype.next = function(callingFormName) {
    switch (callingFormName) {
        case 'billingAddressForm':
            this.forms['shippingMethodForm'].initStep();
            App.Utils.scrollTo(this.forms['shippingMethodForm'].block, -50);
            break;
        case 'shippingMethodForm':
            this.forms['shippingAddressForm'].initStep();
            if ('personal' != this.forms['shippingMethodForm'].type) {
                App.Utils.scrollTo(this.forms['shippingAddressForm'].block, -50);
            }
            break;
        case 'shippingAddressForm':
            this.forms['paymentMethodForm'].initStep();
            App.Utils.scrollTo(this.forms['paymentMethodForm'].block, -50);
            if (this.forms['carForm']) {
                this.forms['carForm'].initStep();
            }
            this.forms['noticeForm'].initStep();
            break;
        default: break;
    }
};

CheckoutForm.prototype.back = function(callingFormName) {
    //switch (callingFormName) {
    //    case 'billingAddressForm':
    //        this.forms['shippingMethodForm'].backStep();
    //    case 'shippingMethodForm':
    //        this.forms['shippingAddressForm'].backStep();
    //    case 'shippingAddressForm':
    //        this.forms['paymentMethodForm'].backStep();
    //    case 'paymentMethodForm':
    //        if (this.forms['carForm']) {
    //            this.forms['carForm'].backStep();
    //        }
    //        this.forms['noticeForm'].backStep();
    //    default: break;
    //}
};

CheckoutForm.prototype.processHeader = function() {
    var headerMenuId;
    var keys = Object.keys(this.forms);
    var chainBreaked = false;
    for (var i = 0, len = keys.length; i < len; ++i) {
        headerMenuId = '#' + keys[i] + 'Menu';
        if (chainBreaked) {
            $(headerMenuId).removeClass('active success');
            continue;
        }
        if (this.forms[keys[i]].isValid()) {
            $(headerMenuId).addClass('active success');
        } else {
            // the chain break
            chainBreaked = true;
            $(headerMenuId).removeClass('active success');
            if (this.forms[keys[i]].block.hasClass('active')) {
                // set the chain last element to active
                $(headerMenuId).addClass('active');
            }
        }
    }
};

CheckoutForm.prototype.init = function() {
    $('#checkout-goto-next-step-button').prop('disabled', true);
    var keys = Object.keys(this.forms);
    for (var i = 0, len = keys.length; i < len; ++i) {
        this.forms[keys[i]].init();
    }
    this.processHeader();
};

CheckoutForm.prototype.isValid = function() {
    var keys = Object.keys(this.forms);
    var len = keys.length;
    if (0 < len) {
        for (var i = 0; i < len; ++i) {
            if (!this.forms[keys[i]].isValid()) {
                return false;
            }
        }
    }
    return true;
};

CheckoutForm.prototype.displayErrors = function() {
    var keys = Object.keys(this.forms);
    var len = keys.length;
    if (0 < len) {
        for (var i = 0; i < len; ++i) {
            if (typeof this.forms[keys[i]].displayErrors === 'function') {
                this.forms[keys[i]].displayErrors();
            }
        }
    }
};

CheckoutForm.prototype.process = function() {
    this.processHeader();
    if (this.isValid()) {
        $('#checkout-goto-next-step-button').prop('disabled', false);
    } else {
        $('#checkout-goto-next-step-button').prop('disabled', true);
    }
};

CheckoutForm.prototype.send = function(url) {
    var data = {};
    if (this.isValid()) {
        var keys = Object.keys(this.forms);
        var len = keys.length;
        if (0 < len) {
            for (var i = 0; i < len; ++i) {
                var d = this.forms[keys[i]].getData();
                for (var key in d) {
                    data[key] = d[key];
                }
            }
            App.Utils.sendForm(url, data);
        }
    }
};

var checkoutForm = new CheckoutForm();

// sidebar box rail and header scroll
(function ($,App) {

    var rail = $('#checkout-rail');
    var train = $('#checkout-train');
    if ( (0 < rail.length) && (0 < train.length) ) {
        // sidebar box rail
        var scroller = new Scroller(rail, train);
        scroller.addCallback('getStop', function () {
            return ((scroller.rail.outerHeight() + scroller.railOffset.top) - scroller.train.outerHeight() ) - scroller.preStop;
        });
        scroller.run(App.ScrollerManager);
    }
    // header scroll menu
    $('.checkout-process-menu .jump').on('click', function(event) {
        var selector = $(event.currentTarget).data('goto');
        App.Utils.scrollTo($(selector), -80);
        event.preventDefault();
    });

})(jQuery, App);

// shipping billing address copy
(function ($,App) {

    //$('#copy-billing-data-button').on('click', function(event){
    //    var fields = ['last_name', 'first_name', 'company', 'zip', 'city', 'address', 'phone'];
    //    var i = 0, len = fields.length;
    //    // begin copy billing address into shipping form
    //    for (i = 0; i < len; ++i) {
    //        var value = $('#billing_address_' + fields[i]).val();
    //        var currentValue = $('#shipping_address_' + fields[i]).val();
    //        if ('' == currentValue) {
    //            // after filling, need to trigger blur event
    //            $('#shipping_address_' + fields[i]).val(value).trigger('blur');
    //        }
    //    }
    //});

    $('#same_as_billing').on('change',function(event){
        var input = $(event.currentTarget);
        var fields = ['last_name', 'first_name', 'company', 'zip', 'city', 'address', 'phone'];
        var i = 0, len = fields.length;
        if (input.is(':checked')) {
            // begin copy billing address into shipping form
            for (i = 0; i < len; ++i) {
                var value = $('#billing_address_' + fields[i]).val();
                $('#shipping_address_' + fields[i]).val(value).trigger('blur').prop("readonly", true);
            }
        } else {
            for (i = 0; i < len; ++i) {
                $('#shipping_address_' + fields[i]).prop("readonly", false).trigger('blur');
            }
        }
    });

    $('#shipping_address_zip').parent('.input').on('click', function(event){
        // TODO not works, need to add this on city
        // disable the shipping address zip click and search form
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
    });

})(jQuery, App);

// autocomplete events for zip and city
(function ($,App) {

    function autocompleteAddress(type, fieldSelector) {
        var input = $(fieldSelector);
        var pairSelector = input.data('pair-selector');
        var autocompleteUrl = input.data('autocomplete-url');
        $.fn.search.settings.templates.message = function(message, type) {
            var
                html = ''
                ;
            if(message !== undefined && type !== undefined) {
                html +=  ''
                    + '<div class="message ' + type + '">'
                ;
                // message type
                if(type == 'empty') {
                    html += ''
                        + '<div class="description">' + message + '</div>'
                    ;
                }
                else {
                    html += ' <div class="description">' + message + '</div>';
                }
                html += '</div>';
            }
            return html;
        };
        $(fieldSelector).parent('.field-input-wrapper').search({
            apiSettings: {
                url: autocompleteUrl + "?searchType="+type+"&searchText={query}",
                minCharacters: 2,
                onResponse: function (results) {
                    var response = {
                        results: []
                    };
                    $.each(results, function (index, item) {
                        if ('zip' == type) {
                            response.results.push({
                                title: item.zip,
                                description: item.city,
                                pair: item.city
                            });
                        } else {
                            response.results.push({
                                title: item.city,
                                description: item.zip,
                                pair: item.zip
                            });
                        }
                    });
                    return response;
                }
            },
            error : {
                source      : App.t('Cannot search. No source used, and Semantic API module was not included'),
                noResults   : App.t('Your search returned no results'),
                logging     : App.t('Error in debug logging, exiting.'),
                noTemplate  : App.t('A valid template name was not specified.'),
                serverError : App.t('There was an issue with querying the server.'),
                maxResults  : App.t('Results must be an array to use maxResults setting'),
                method      : App.t('The method you called is not defined.')
            },
            onSelect: function(result, response) {
                $(pairSelector).val(result.pair);
                // if clicked on search list, wait and recheck the field again
                // because need to the error message disappear
                // and need to check pair selector
                // because it is not blured by autocomplete
                setTimeout(function(){
                    input.trigger('blur');
                    $(pairSelector).trigger('blur');
                }, 200);
            }
        });
        input.prop('autocomplete', 'new-password');
    }

    if ($('body').hasClass('page-checkout')) {
        autocompleteAddress('zip', '#billing_address_zip');
        autocompleteAddress('city', '#billing_address_city');
        autocompleteAddress('zip', '#shipping_address_zip');
        autocompleteAddress('city', '#shipping_address_city');
    }

})(jQuery, App);

// info box display
(function ($,App) {

    $('.corner-help-field .info-box-switch')
        .on('mouseenter', function(event){
            var _switch = $(event.currentTarget);
            _switch.parent().find('.info-box').show();
        })
        .on('mouseleave', function(event){
            var _switch = $(event.currentTarget);
            _switch.parent().find('.info-box').hide();
        })
        .on('click',function(event){
            var _switch = $(event.currentTarget);
            _switch.parent().find('.info-box').toggle();
        })
        .parent().find('.info-box').hide()
    ;

})(jQuery, App);

// billing address form
(function ($,App,checkoutForm) {
    let vatinValidationResultsCache = {};
    let runningValidations = new Set();
    function BillingAddressForm() {
        this.validFields = [];
        this.block = $('#billing-address-form');
        this.continueButton = this.block.find('.continue-button');
        this.headerSelect = this.block.find('.user-addresses');
        this.validator = App.Validator;
        this.errorSkeleton = '<div class="error-label ui basic red pointing below prompt label transition visible"></div>';
        this.fields = {
            billing_address_first_name: {
                element: $('#billing_address_first_name'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().maxLen(32)
                        .isValid();
                }
            },
            billing_address_last_name: {
                element: $('#billing_address_last_name'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().maxLen(32)
                        .isValid();
                }
            },
            billing_address_email: {
                element: $('#billing_address_email'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().email()
                        .isValid();
                }
            },
            billing_address_company: {
                element: $('#billing_address_company'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .ifNotEmpty().maxLen(90)
                        .isValid();
                }
            },
            billing_address_vatin: {
                isValid: function(validator, element) {

                    var required = false;
                    if (0 < self.fields.billing_address_company.element.val().length) {
                        required = true;
                    }

                    validator.validate(element.val());

                    if (required && !validator.required(App.t('If you filled the company field, fill this field also!')).isValid()) {
                        return false;
                    }

                    if (!required) {
                        validator.ifNotEmpty();
                    }

                    if (!validator.maxLen(64).isValid()) {
                        return false;
                    }

                    var format = '' + element.data('mask-human');
                    var mask = element.data('mask');

                    if (!format.length) {
                        format = mask;
                    }
                    if (mask && !validator.mask(mask, App.t('The format must be: %mask%', { '%mask%': format })).isValid()) {
                        return false;
                    }

                    var apiValidatorUrl = element.data('api-validator');

                    if (typeof apiValidatorUrl === 'undefined') {
                        // no API url, skip the check, just return true
                        return true;
                    }

                    let vatinNumber = element.val();
                    if ('' === vatinNumber) {
                        // empty nothing to do, return true
                        return true;
                    }

                    let vatinData = {};

                    if (!vatinValidationResultsCache.hasOwnProperty(vatinNumber)) {
                        // do api validation
                        // notice: if the api not working, we ignore and return true
                        if (runningValidations.has(vatinNumber)) {
                            // do not create a new request, which is already ongoing
                            return true;
                        }
                        runningValidations.add(vatinNumber);

                        $.ajax({
                            type: 'POST',
                            dataType: 'json',
                            url: apiValidatorUrl,
                            data: {
                                vatin: vatinNumber
                            },
                            success: function(resp) {
                                if (!vatinValidationResultsCache.hasOwnProperty(vatinNumber)) {
                                    // add to cache
                                    let cache = {};
                                    if (resp.data.hasOwnProperty('taxpayerValidity')) {
                                        cache.taxpayerValidity = resp.data.taxpayerValidity;
                                    }
                                    if (resp.data.hasOwnProperty('taxpayerShortName')) {
                                        cache.taxpayerName = resp.data.taxpayerShortName.substring(0,90);
                                    } else if (resp.data.hasOwnProperty('taxpayerName')) {
                                        cache.taxpayerName = resp.data.taxpayerName.substring(0,90);
                                    }
                                    if (resp.data.hasOwnProperty('taxpayerAddresses') && resp.data.taxpayerAddresses.length) {
                                        if (resp.data.taxpayerAddresses.hasOwnProperty('city')) {
                                            cache.taxpayerAddressCity = resp.data.taxpayerAddresses.hasOwnProperty('city');
                                        }
                                        if (resp.data.taxpayerAddresses.hasOwnProperty('postalCode')) {
                                            cache.taxpayerAddressZip = resp.data.taxpayerAddresses.hasOwnProperty('postalCode');
                                        }
                                        let streetData = [];
                                        if (resp.data.taxpayerAddresses.hasOwnProperty('streetName')) {
                                            streetData.push(resp.data.taxpayerAddresses.hasOwnProperty('streetName'));
                                        }
                                        if (resp.data.taxpayerAddresses.hasOwnProperty('publicPlaceCategory')) {
                                            streetData.push(resp.data.taxpayerAddresses.hasOwnProperty('publicPlaceCategory'));
                                        }
                                        if (resp.data.taxpayerAddresses.hasOwnProperty('number')) {
                                            streetData.push(resp.data.taxpayerAddresses.hasOwnProperty('number'));
                                        }
                                        if (streetData.length) {
                                            cache.taxpayerAddressStreet = streetData.join(' ');
                                        }
                                    }
                                    vatinValidationResultsCache[vatinNumber] = cache;
                                }
                            },
                            error: function(resp) {
                                if (400 === resp.status) {
                                    // not valid or not exists
                                    if (resp.responseJSON.data) {
                                        if (resp.responseJSON.data.hasOwnProperty('taxpayerValidity')) {
                                            vatinValidationResultsCache[vatinNumber] = { taxpayerValidity: false};
                                        }
                                    }
                                }
                            },
                            complete: () => {
                                // rerun the validation
                                self.isValid('billing_address_vatin');
                                runningValidations.delete(vatinNumber);
                            }
                        });
                        return true;
                    }

                    vatinData = vatinValidationResultsCache[vatinNumber];
                    if (!vatinData) {
                        // some error happen, just return true
                        return true;
                    }

                    if (!vatinData.taxpayerValidity) {
                        validator.errors.push(App.t('This value is not valid!'));
                        return false;
                    }

                    // valid vatin, try to some form filling
                    if (vatinData.taxpayerName) {
                        self.fields.billing_address_company.element.val(vatinData.taxpayerName);
                        self.fields.billing_address_company.element.trigger('change');
                    }

                    if (vatinData.taxpayerAddressCity && !self.fields.billing_address_city.element.val().length) {
                        self.fields.billing_address_city.element.val(vatinData.taxpayerAddressCity);
                        self.fields.billing_address_city.element.trigger('change');
                    }

                    if (vatinData.taxpayerAddressZip && !self.fields.billing_address_zip.element.val().length) {
                        self.fields.billing_address_zip.element.val(vatinData.taxpayerAddressZip);
                        self.fields.billing_address_zip.element.trigger('change');
                    }
                    if (vatinData.taxpayerAddressStreet && !self.fields.billing_address_address.element.val().length) {
                        self.fields.billing_address_address.element.val(vatinData.taxpayerAddressStreet);
                        self.fields.billing_address_address.element.trigger('change');
                    }

                    return true;

                },
                element : $('#billing_address_vatin')
            },
            billing_address_zip: {
                element: $('#billing_address_zip'),
                isValid: function(validator, element) {
                    var mask = element.data('mask');
                    var format = "" + element.data('mask-human');
                    if (!format.length) {
                        format = mask;
                    }
                    if (mask) {
                        return validator.validate(element.val())
                            .required()
                            .mask(mask, App.t('The format must be: %mask%', { '%mask%': format }))
                            .isValid();
                    } else {
                        return validator.validate(element.val())
                            .required()
                            .isValid();
                    }
                }
            },
            billing_address_city: {
                element: $('#billing_address_city'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().maxLen(32)
                        .isValid();
                }
            },
            billing_address_address: {
                element: $('#billing_address_address'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().maxLen(250)
                        .isValid();
                }
            },
            billing_address_phone: {
                element: $('#billing_address_phone'),
                isValid: function(validator, element) {
                    var mask = element.data('mask');
                    var format = "" + element.data('mask-human');
                    if (!format.length) {
                        format = mask;
                    }
                    return validator.validate(element.val())
                        .required().maxLen(32)
                        .mask(mask, App.t('The format must be: %mask%', { '%mask%': format }))
                        .isValid();
                }
            }
        };
    }

    BillingAddressForm.prototype.isValid = function(name) {
        name = name || '';
        var valid = true;

        if ('' == name) {
            for (var key in this.fields) {
                if (!this.fields[key].isValid(this.validator, this.fields[key].element)) {
                    valid = false;
                    break;
                }
            }
        } else {
            if (this.fields.hasOwnProperty(name)) {
                valid = this.fields[name].isValid(this.validator, this.fields[name].element);
            }
        }

        return valid;
    };

    BillingAddressForm.prototype.displayError = function(name) {
        name = name || '';
        if (!this.fields.hasOwnProperty(name)) {
            return;
        }

        var prompt = this.fields[name].element.parents('.field').find('.error-label');
        if (this.fields[name].isValid(this.validator, this.fields[name].element)) {
            if (prompt.length) {
                this.fields[name].element.parents('.field').find('.error-label').remove();
            }
        } else {
            var msg = this.validator.getError();
            if (prompt.length) {
                if (prompt.html() != msg) {
                    prompt.html(msg);
                }
            } else {
                var error = this.errorSkeleton;
                this.fields[name].element.parents('.field').append($(error).html(msg));
            }
        }
    };

    BillingAddressForm.prototype.displayErrors = function() {
        for (var key in this.fields) {
            if (this.fields.hasOwnProperty(key)) {
                this.displayError(key);
            }
        }
    };

    BillingAddressForm.prototype.getData = function() {
        var data = {};
        for (var key in this.fields) {
            data[key] = $('#' + key).val();
        }
        return data;
    };

    BillingAddressForm.prototype.init = function () {

        $('#billing_address_company').on('change', function (event) {
            var company = $(event.currentTarget).val().trim();
            if ('' === company) {
                $('#billing_address_vatin').prop('placeholder', '').parents('.field').removeClass('required');
            } else {
                $('#billing_address_vatin').prop('placeholder', '(' + App.t('required') + ')').parents('.field').addClass('required');
            }
        });

        $('#billing_address_vatin').on('change', function(event) {
            var vatin = $(event.currentTarget).val().trim();
            if ('' === vatin) {
                $('#billing_address_company').prop('placeholder', '').parents('.field').removeClass('required');
            } else {
                $('#billing_address_company').prop('placeholder', '(' + App.t('required') + ')').parents('.field').addClass('required');
            }
        });

        // billing form validate js
        for (var key in self.fields) {
            if (self.fields.hasOwnProperty(key)) {
                self.fields[key].element.on('change', function (event) {
                    self.displayError(event.currentTarget.id);
                });
                self.fields[key].element.on('focusout', function (event) {
                    $(event.currentTarget).trigger('change');
                });
                self.fields[key].element.on('keyup', function (event) {
                    if (9 != event.which) {
                        $(event.currentTarget).trigger('change');
                    }
                });
            }
        }

        this.headerSelect.on('change', function(event) {
            var value = self.headerSelect.val();
            if (!value) {
                return;
            }
            var address = self.headerSelect.find('option:selected').data();
            var keys = [ 'first_name', 'last_name', 'email', 'company', 'vatin', 'zip', 'city', 'address', 'phone'];
            var prefix = 'billing_address_';
            var key = '';
            var id = '';
            for (var i= 0,len=keys.length;i<len;i++) {
                key = keys[i];
                id = prefix + key;
                $('#'+id).val(address[key]).trigger('change');
            }
            self.headerSelect.val('');
        });

        self.continueButton.on('click', function(event){
            // check the form
            if (self.isValid()) {
                // if valid, go to next form
                checkoutForm.next('billingAddressForm');
            } else {
                self.displayErrors();
            }
        });

    };

    var self = new BillingAddressForm();
    checkoutForm.addForm("billingAddressForm", self);

})(jQuery, App, checkoutForm);

// shipping method form
(function ($,App,checkoutForm) {

    function ShippingMethodForm() {
        this.block = $('#shipping-method-form');
        this.continueButton = this.block.find('.continue-button');
        this.type = '';
        this.setMethodUrl = this.block.data('url');
    }

    ShippingMethodForm.prototype.getData = function() {
        var data = {};
        var selected = this.block.find('.shipping_method:checked');
        if (selected.length) {
            data['shipping_method'] = selected.val();
        } else {
            data['shipping_method'] = '0';
        }
        if (selected.parents('.shipping-method-item').find('#place_of_collection').length && $('#place_of_collection').val()) {
            data['place_of_collection'] = $('#place_of_collection').val();
        }
        return data;
    };

    ShippingMethodForm.prototype.isValid = function() {
        // shipping method form validate js
        var valid = true;
        var selected = $('#shipping-method-form .shipping_method:checked');
        if (selected.length) {
            this.type = selected.data('type');
            if ('personal' == selected.data('type')) {
                // check the place of collection
                if (0 >= $('#place_of_collection').val()) {
                    valid = false;
                }
            }
        } else {
            valid = false;
        }
        return valid;
    };

    ShippingMethodForm.prototype.displayErrors = function() {

    };

    ShippingMethodForm.prototype.init = function () {
        self.block.on('change', '.shipping_method', function(event){
            $('#shipping-method-form .shipping-method-item').removeClass('selected');
            $('#shipping-method-form .shipping_method:checked').parents('.shipping-method-item').addClass('selected');
            checkoutForm.forms['paymentMethodForm'].shippingChanged();

            var shippingMethodData = self.getData();
            $.ajax({
                type: 'POST',
                dataType: 'json',
                url: self.setMethodUrl,
                data: shippingMethodData
            })
            .done(function(data) {
                if ('success' == data.status) {
                    checkoutForm.reloadSidebarSummary();
                    //modal.find('.answer-box').addClass('invisible');
                    //modal.find('.success-box').removeClass('invisible');
                } else {
                    //for (var len = data.errors.length - 1; 0 <= len; len--) {
                    //    App.Messages.add(data.errors[len], 'error', 10);
                    //}
                }
            })
            .fail(function(data) {
                //App.Messages.add(modal.data('fail'),'error', 10);
            })
            .always(function() {
                //modal.dimmer('hide');
            })
            ;
            self.process();
        });

        self.block.on('change', '#place_of_collection', function(event){
            // click on main radio, if not selected already
            $(event.currentTarget).parents('.shipping-method-item').find('.shipping_method').click().trigger('change');
        });

        self.continueButton.on('click', function(event){
            // check the form
            if (self.isValid()) {
                // if valid, go to next form
                checkoutForm.next('shippingMethodForm');
            }
        });

        self.process();
    };

    ShippingMethodForm.prototype.process = function() {
        if (this.isValid()) {
            if (self.block.hasClass('success')) {
                // when changed from success to succes in one click
                checkoutForm.process();
            } else {
                self.block.addClass('success');
            }
        } else {
            if (self.block.hasClass('success')) {
                self.block.removeClass('success');
            }
        }
        if (this.type == 'delivery') {
            $('#shipping-address-form').removeClass('invisible');
        } else {
            $('#shipping-address-form').addClass('invisible');
        }
        checkoutForm.process();
    };

    ShippingMethodForm.prototype.initStep = function() {
        self.block.addClass('active');
    };

    ShippingMethodForm.prototype.backStep = function() {
        self.block.removeClass('active');
    };

    var self = new ShippingMethodForm();
    checkoutForm.addForm('shippingMethodForm', self);

})(jQuery, App, checkoutForm);

// shipping address form
(function ($,App,checkoutForm) {

    function ShippingAddressForm() {
        this.validFields = [];
        this.fieldsNeedToValid = ['shipping_address_first_name', 'shipping_address_last_name', 'shipping_address_zip', 'shipping_address_city', 'shipping_address_address', 'shipping_address_phone'];
        this.block = $('#shipping-address-form');
        this.continueButton = this.block.find('.continue-button');
        this.headerSelect = this.block.find('.user-addresses');
        this.validator = App.Validator;
        this.errorSkeleton = '<div class="error-label ui basic red pointing below prompt label transition visible"></div>';
        this.fields = {
            same_as_billing: {
                element: $('#same_as_billing'),
                isValid: function(validator, element) {
                    return true;
                }
            },
            shipping_address_first_name: {
                element: $('#shipping_address_first_name'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().maxLen(32)
                        .isValid();
                }
            },
            shipping_address_last_name: {
                element: $('#shipping_address_last_name'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().maxLen(32)
                        .isValid();
                }
            },
            shipping_address_company: {
                element: $('#shipping_address_company'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .ifNotEmpty().maxLen(64)
                        .isValid();
                }
            },
            shipping_address_zip: {
                element: $('#shipping_address_zip'),
                isValid: function(validator, element) {
                    var mask = element.data('mask');
                    var format = "" + element.data('mask-human');
                    if (!format.length) {
                        format = mask;
                    }
                    if (mask) {
                        return validator.validate(element.val())
                            .required()
                            .mask(mask, App.t('The format must be: %mask%', { '%mask%': format }))
                            .isValid();
                    } else {
                        return validator.validate(element.val())
                            .required()
                            .isValid();
                    }
                }
            },
            shipping_address_city: {
                element: $('#shipping_address_city'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().maxLen(32)
                        .isValid();
                }
            },
            shipping_address_address: {
                element: $('#shipping_address_address'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().maxLen(250)
                        .isValid();
                }
            },
            shipping_address_phone: {
                element: $('#shipping_address_phone'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().maxLen(32)
                        .isValid();
                }
            }
        };
    }

    ShippingAddressForm.prototype.isValid = function(name) {
        name = name || '';
        var valid = true;

        switch (checkoutForm.forms['shippingMethodForm'].type) {

            case 'personal':
                break;

            default:
                if ('' == name) {
                    for (var key in this.fields) {
                        if (!this.fields[key].isValid(this.validator, this.fields[key].element)) {
                            valid = false;
                            break;
                        }
                    }
                } else {
                    if (this.fields.hasOwnProperty(name)) {
                        valid = this.fields[name].isValid(this.validator, this.fields[name].element);
                    }
                }
                break;
        }

        return valid;
    };

    ShippingAddressForm.prototype.displayError = function(name) {
        name = name || '';
        if (this.fields.hasOwnProperty(name)) {
            var prompt = this.fields[name].element.parents('.field').find('.error-label');
            if (this.fields[name].isValid(this.validator, this.fields[name].element)) {
                if (prompt.length) {
                    this.fields[name].element.parents('.field').find('.error-label').remove();
                }
            } else {
                var msg = this.validator.getError();
                if (prompt.length) {
                    if (prompt.html() != msg) {
                        prompt.html(msg);
                    }
                } else {
                    var error = this.errorSkeleton;
                    this.fields[name].element.parents('.field').append($(error).html(msg));
                }
            }
        }
    };

    ShippingAddressForm.prototype.displayErrors = function() {
        for (var key in this.fields) {
            this.displayError(key);
        }
    };

    ShippingAddressForm.prototype.getData = function() {
        var data = {};
        for (var key in this.fields) {
            if ('same_as_billing' == key) {
                data[key] = '0';
                if ($('#same_as_billing').prop('checked')) {
                    data[key] = '1';
                }
            } else {
                data[key] = $('#' + key).val();
            }
        }
        return data;
    };

    ShippingAddressForm.prototype.isEmptyForm = function() {
        var data = this.getData();
        var isEmpty = true;
        for (var key in data) {
            if ('' != data[key] && '0' != data[key]) {
                isEmpty = false;
                break;
            }
        }
        return isEmpty;
    };

    ShippingAddressForm.prototype.init = function() {

        // shipping form validate js
        for (var key in self.fields) {
            self.fields[key].element.on('change', function(event){
                self.displayError(event.currentTarget.id);
            });
            self.fields[key].element.on('focusout', function(event){
                $(event.currentTarget).trigger('change');
            });
            self.fields[key].element.on('keyup', function(event){
                if (9 != event.which) {
                    $(event.currentTarget).trigger('change');
                }
            });
        }

        this.headerSelect.on('change', function(event) {
            var value = self.headerSelect.val();
            if (!value) {
                return;
            }
            var address = self.headerSelect.find('option:selected').data();
            var keys = [ 'first_name', 'last_name', 'company', 'zip', 'city', 'address', 'phone'];
            var prefix = 'shipping_address_';
            var key = '';
            var id = '';
            for (var i= 0,len=keys.length;i<len;i++) {
                key = keys[i];
                id = prefix + key;
                $('#'+id).val(address[key]).trigger('change');
            }
            $('#same_as_billing:checked').prop('checked', false);
            self.headerSelect.val('');
        });


        self.continueButton.on('click', function(event){
            // check the form
            if (self.isValid()) {
                // if valid, go to next form
                checkoutForm.next('shippingAddressForm');
            }
        });

    };

    ShippingAddressForm.prototype.initStep = function() {
        switch (checkoutForm.forms['shippingMethodForm'].type) {
            case 'personal':
                self.block.addClass('invisible');
                checkoutForm.next('shippingAddressForm');
                break;
            default:
                self.block.removeClass('invisible');
                self.block.addClass('active');
                var firstTimeDisplay = $('#checkout').data('first-time');
                if ('1' == firstTimeDisplay) {
                    if (this.isEmptyForm()) {
                        $('#same_as_billing').click();
                    }
                }
                break;
        }
        checkoutForm.process();
    };

    ShippingAddressForm.prototype.backStep = function() {
        self.block.removeClass('invisible active success');
        checkoutForm.process();
    };

    var self = new ShippingAddressForm();
    checkoutForm.addForm("shippingAddressForm", self);

})(jQuery, App, checkoutForm);

// payment method form
(function ($,App,checkoutForm) {

    function PaymentMethodForm() {
        this.block = $('#payment-method-form');
        this.continueButton = this.block.find('.continue-button');
        this.loadUrl = this.block.data('url');
        this.checkMarsoPartnerUrl = this.block.data('check-marso-partner-url');
        this.checkedMarsoPartners = {};
    }

    PaymentMethodForm.prototype.getData = function() {
        var data = {};
        var selected = this.block.find('.payment_method:checked');
        if (selected.length) {
            data['payment_method'] = selected.val();
        } else {
            data['payment_method'] = '0';
        }
        return data;
    };

    PaymentMethodForm.prototype.init = function () {

        $('#payment-method-form').on('change', '.payment_method', function(event){
            $('#payment-method-form .payment-method-item').removeClass('selected');
            $('#payment-method-form .payment_method:checked').parents('.payment-method-item').addClass('selected');
            self.process();
        });

        self.continueButton.on('click', function(event){
            // check the form
            if (self.isValid()) {
                // if valid, go to next form
                checkoutForm.next('paymentMethodForm');
            }
        });
        // if shipping method already selected, need to run the shippingChanged function
        let shippingMethodData = checkoutForm.forms['shippingMethodForm'].getData();
        if (shippingMethodData) {
            self.shippingChanged();
        }
    };

    PaymentMethodForm.prototype.displayErrors = function() {

    };

    PaymentMethodForm.prototype.checkMarsoPartner = function (block) {
        var shippingMethodData = checkoutForm.forms['shippingMethodForm'].getData();
        if (!shippingMethodData.place_of_collection) {
            return;
        }
        let placeOfCollection = String(shippingMethodData.place_of_collection);
        if (self.checkedMarsoPartners.hasOwnProperty(placeOfCollection)) {
            if (self.checkedMarsoPartners[placeOfCollection]) {
                // if true, need to disable
                if (!block.hasClass('invisible')) {
                    block.addClass('invisible');
                }
            }
            return;
        }
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: self.checkMarsoPartnerUrl,
            data: shippingMethodData.place_of_collection
        }).done( (response) => {
            self.checkedMarsoPartners[placeOfCollection] = response;
            if (response) {
                if (!block.hasClass('invisible')) {
                    block.addClass('invisible');
                }
            }
        });
    };

    PaymentMethodForm.prototype.shippingChanged = function() {
        var shippingMethodData = checkoutForm.forms['shippingMethodForm'].getData();
        var selector = 'shipping-' + shippingMethodData['shipping_method'];

        self.block.find('.payment-method-item').each(function(i){
            var block = $(this);
            var input = block.find('.payment_method');

            if (input.prop('checked')) {
                if (block.hasClass(selector)) {
                    block.removeClass('invisible');
                } else {
                    input.prop('checked', false).trigger('change');
                    block.addClass('invisible');
                }
            } else {
                if (block.hasClass(selector)) {
                    block.removeClass('invisible');
                } else {
                    block.addClass('invisible');
                }
            }

            // TODO: remove this block if not necessary anymore
            if (
                $('html').prop('lang') == 'hu-HU'
                && (block.hasClass('kh_credit_card') || block.hasClass('simplepay') || block.hasClass('raiffeisen'))
                && typeof shippingMethodData.place_of_collection !== 'undefined'
                && !block.hasClass('invisible')
            ) {
                var bannedPlacesOfCollection = [4,8,14,15,27,17,18,19,20,29,21,23,26,27];
                if ($.inArray(parseInt(shippingMethodData.place_of_collection), bannedPlacesOfCollection) !== -1) {
                    input.prop('checked', false).trigger('change');
                    block.addClass('invisible');
                }
            }

            // Separate cetelem block
            // TODO: remove this block if not necessary anymore
            if (
                $('html').prop('lang') == 'hu-HU'
                && (block.hasClass('cetelem'))
                && typeof shippingMethodData.place_of_collection !== 'undefined'
                && !block.hasClass('invisible')
            ) {
                var bannedPlacesOfCollection = [4,8,14,15,27,17,18,19,21,23,26,27];
                if ($.inArray(parseInt(shippingMethodData.place_of_collection), bannedPlacesOfCollection) !== -1) {
                    input.prop('checked', false).trigger('change');
                    block.addClass('invisible');
                }
            }

            if (block.hasClass('cash')) {
                self.checkMarsoPartner(block);
            }
        });
    };

    PaymentMethodForm.prototype.initStep = function() {
        self.shippingChanged();
        self.block.addClass('active');
        self.process();
    };

    PaymentMethodForm.prototype.backStep = function() {
        self.block.removeClass('active');
        self.block.removeClass('success');
        self.process();
    };

    PaymentMethodForm.prototype.isValid = function() {
        // payment method form validate js
        var valid = true;
        var selected = $('#payment-method-form .payment_method:checked');
        if (1 > selected.length) {
            valid = false;
        }
        return valid;
    };

    PaymentMethodForm.prototype.process = function() {
        if (this.isValid()) {
            if (self.block.hasClass('active')) {
                self.block.addClass('success');
            } else {
                self.block.removeClass('success');
            }

        } else {
            if (self.block.hasClass('success')) {
                self.block.removeClass('success');
            }
        }
        checkoutForm.process();
    };

    var self = new PaymentMethodForm();
    checkoutForm.addForm("paymentMethodForm", self);

})(jQuery, App, checkoutForm);

// car (optional) form
(function ($,App,checkoutForm) {

    if (!$('body').hasClass('page-checkout') || !$('#car-data-form').length) {
        return;
    }

    App.CarForm.getData = function() {
        var data = {};
        for (var key in this.fields) {
            data[key] = $('#' + key).val();
        }
        return data;
    };

    App.CarForm.isValid = function() {
        // always valid, because it is optional
        return true;
    };

    App.CarForm.displayErrors = function() {

    };

    App.CarForm.initStep = function() {
        this.form.addClass('active');
        this.process();
    };

    App.CarForm.backStep = function() {
        this.form.removeClass('active');
        this.process();
    };

    App.CarForm.process = function() {
        if (this.isValid()) {
            if (this.form.hasClass('active')) {
                this.form.addClass('success');
                checkoutForm.next('carForm');
            } else {
                this.form.removeClass('success');
            }
        } else {
            if (this.form.hasClass('success')) {
                this.form.removeClass('success');
                checkoutForm.back('carForm');
            }
        }
        checkoutForm.process();
    };

    App.CarForm.removeValidField = function(fieldId) {
        var pos = $.inArray(fieldId, this.validFields);
        if (-1 < pos) {
            this.validFields.splice(pos, 1);
            return true;
        }
        return false;
    };

    App.CarForm.fillFromUserCars = function() {
        var carData = this.form.find('#car_id option:selected').data(),
            self = this;
        $.each(carData, function(id, val) {
            if (typeof self.fields[id] !== 'undefined') {
                self.fields[id].element.val(val);
                self.removeValidField(id);
                self.fields[id].element.trigger('change').trigger('blur');
            }
        });
        this.initDefaultValues();
        this.loadImage(carData.image);
    };

    App.CarForm.reset = function() {
        var self = this;
        $.each(this.fields, function(i, el) {
            el.element.parents('.dropdown').dropdown('set text', '');
            el.element.parents('.dropdown').dropdown('set value', '');
            self.removeValidField(i);
            el.element.trigger('change').trigger('blur');
        });
        this.loadImage();
        checkoutForm.process();
    };

    App.CarForm.parentInit = App.CarForm.init;
    App.CarForm.init = function() {
        App.CarForm.parentInit();

        this.block = $('#car-data-form');

        var self = this;

        delete this.fields.name;
        delete this.fields.tyre_size;
        delete this.fields.mileage;
        delete this.fields.international_vehicle_registration_code;
        delete this.fields.summer_tyre_front_size;
        delete this.fields.summer_tyre_rear_size;
        delete this.fields.winter_tyre_front_size;
        delete this.fields.winter_tyre_rear_size;
        delete this.fields.license_plate_number;

        // used fields on checkout
        this.fields.brand.isValid = function() { return true; };
        this.fields.brand_id.isValid = function() { return true; };
        this.fields.type.isValid = function() { return true; };
        this.fields.type_id.isValid = function() { return true; };
        this.fields.model.isValid = function() { return true; };
        this.fields.model_id.isValid = function() { return true; };

        this.form.find('#car_id').on('change', function(event) {
            var value = $(event.currentTarget).val();
            if (0 == value) {
                self.reset();
            } else {
                self.fillFromUserCars();
            }
        });
    };

    checkoutForm.addForm("carForm", App.CarForm);

})(jQuery, App, checkoutForm);

// notice form
(function ($,App,checkoutForm) {

    function NoticeForm() {
        this.block = $('#notice-form');
        this.loadUrl = this.block.data('url');
    }

    NoticeForm.prototype.getData = function() {
        var data = {};
        data['comment'] = $('#comment').val();
        return data;
    };

    NoticeForm.prototype.init = function () {};

    NoticeForm.prototype.isValid = function() {
        // always valid, because it is optional
        return true;
    };

    NoticeForm.prototype.initStep = function() {
        self.block.addClass('active');
        self.process();
    };

    NoticeForm.prototype.backStep = function() {
        self.block.removeClass('active');
        self.process();
    };

    NoticeForm.prototype.process = function() {
        if (self.isValid()) {
            if (self.block.hasClass('active')) {
                self.block.addClass('success');
                checkoutForm.next('noticeForm');
            } else {
                self.block.removeClass('success');
            }
        } else {
            if (self.block.hasClass('success')) {
                self.block.removeClass('success');
                checkoutForm.back('noticeForm');
            }
        }
        checkoutForm.process();
    };


    var self = new NoticeForm();
    checkoutForm.addForm("noticeForm", self);

})(jQuery, App, checkoutForm);

// Note: this file need to be end this block
// main checkout form
(function ($,App,checkoutForm) {

    if ($('body').hasClass('page-checkout')) {
        checkoutForm.init();

        $('#checkout-goto-next-step-button').on('click', function(event){
            var button = $(event.currentTarget);
            if (checkoutForm.isValid()) {
                checkoutForm.send(button.data('url'));
            } else {
                checkoutForm.displayErrors();
            }
        });
    }

})(jQuery, App, checkoutForm);
};

export default CheckoutJS;
